import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};



export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: ':checkout_id', element: <Payments />, index: true },
        { path: '/cybersource', element: <CyberSource />},
        // { path: ':merchantCode/:paymentType/:invoiceNumber', element: <Payments />, index: true },
        { path: '/', element: <Navigate to="/404" replace /> },
        { path: '404', element: <NotFound /> },

      ],
    },
  ]);
}

// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Payments = Loadable(lazy(() => import('../pages/Payments')));
const CyberSource = Loadable(lazy(() => import('../pages/Payments/cybersource')));
