import { createSlice } from '@reduxjs/toolkit';
// utils
import { filter } from 'lodash';
import axios from '../../utils/axios';
import { dispatch } from '../store';

import sasapay from '../../assets/images/sasapay.png'
import mpesa from '../../assets/images/mpesa.png'
import airtel from '../../assets/images/airtel.png'

// ----------------------------------------------------------------------
const PAYMENT_OPTIONS = [
  {
    networkCode: '0',
    title: 'Pay with Mobile Money',
    description: 'Complete Payment with Mobile Money',
    icons: [sasapay, mpesa, airtel],
    sx: { width: 70, height: 20 }
  },
  // {
  //   networkCode: '63902',
  //   title: 'Pay with M-Pesa',
  //   description: 'We support M-PESA STK Push & Manual Payment Modes.',
  //   icons: [mpesa],
  //   sx: { width: 70, height: 20 }
  // },
  {
    networkCode: '1',
    title: 'Credit / Debit Card',
    description: 'We support Mastercard, Visa and UnionPay.',
    icons: [
      'https://minimal-assets-api.vercel.app/assets/icons/ic_mastercard.svg',
      'https://minimal-assets-api.vercel.app/assets/icons/ic_visa.svg',
    ],
  },
  // {
  //   networkCode: '63903',
  //   title: 'Pay with Airtel',
  //   description: 'We support Airtel USSD Push & Manual Payment Modes.',
  //   icons: [airtel],
  //   sx: { width: 70, height: 20 }
  // },
]


const initialState = {
  isProcessing: false,
  errors: null,
  merchantCode: '',
  amount: '',
  reference: '',
  merchantName: '',
  orderNumber: '',
  description: '',
  phone: '',
  txnid: '',
  successUrl: '',
  failUrl: '',
  showOTP: false,
  mpesaEnabled: false,
  walletEnabled: false,
  airtelEnabled: false,
  cardEnabled: false,
  invoiceNumber: '',
  networkCode: '',
  toEmail: '',
  dataMap: { byId: {}, allIds: [] },
  paymentOptions: [],
  MerchantRequestID: '',
  CheckoutRequestID: '',
  orderId: '',
  detail: '',
  redirectUrl: '',
  currencyCode: 'KES',
  success: null,
  showComplete: false,
  data: null,
  balance: 0,
  requested_amount: 0,
  jamboPayProcessor: false,
  paid: false,
  ready: false,
  email: ''
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    paymentProcessingState(state, action) {
      state.isProcessing = action.payload;
    },
    // Handle JamboPay Checkout

    jamboPayCheckoutProcessor(state, action) {
      const options = []
      options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === state.networkCode)[0])
      state.paymentOptions = options
      const { isProcessing, showComplete, jamboPayProcessor } = action.payload
      state.isProcessing = isProcessing
      state.showComplete = showComplete;
      state.jamboPayProcessor = jamboPayProcessor
    },
    // OTP View
    toggleOTP(state, action) {
      state.showOTP = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.success = null
      state.isProcessing = false;
      state.errors = action.payload;
    },
    // HAS SUCCESS
    hasSuccess(state, action) {
      state.errors = null
      state.isProcessing = false;
      state.success = action.payload;
    },
    // IS COMPLETE
    setComplete(state, action) {
      state.errors = null
      state.success = action.payload.success || null;
      state.showComplete = action.payload.showComplete || false;;
    },
    // SET BASIC INFO
    setBasic(state, action) {
      state.CheckoutId = action.payload.CheckoutId
    },
    // SELECT NET
    setNetworkCode(state, action) {
      state.networkCode = action.payload
    },
    // GET PAYMENT SSUCCESS
    getPaymentInfoSuccess(state, action) {
      state.data = action.payload;
      const payload = action.payload;
      state.merchantName = payload.merchant_name
      state.amount = payload.amount
      state.balance = payload.balance
      state.requested_amount = payload.requested_amount
      state.reference = payload.reference
      state.description = payload.description
      state.successUrl = payload.successUrl
      state.redirectUrl = payload.redirectUrl
      state.failUrl = payload.failUrl
      state.currencyCode = payload.currency_code
      state.isProcessing = false;
      state.mpesaEnabled = payload.mpesaEnabled
      state.walletEnabled = payload.walletEnabled
      state.cardEnabled = false // payload.cardEnabled
      state.airtelEnabled = payload.airtelEnabled
      state.orderId = payload.orderId
      state.email = payload.email || ''
      state.processingFee = payload.processing_fee || 0
      state.paid = payload.paid || 0
      state.errors = null
      state.success = null
      state.showComplete = false;
      state.ready = true

      const options = []
      options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '0')[0])

      // options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '1')[0])
      state.networkCode = options[0].networkCode

      // if (state.walletEnabled) {
      //   options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '0')[0])
      // }

      // if (state.mpesaEnabled) {
      //   options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '63902')[0])
      // }
      // if (state.cardEnabled) {
      //   options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '1')[0])
      // }
      // if (state.airtelEnabled) {
      //   options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === '63903')[0])
      // }

      // if (options.length > 0) {
      //   state.networkCode = options[0].networkCode
      // }

      state.paymentOptions = options
    },

    // POST PAYMENT SUCCESS
    initiatePayment(state, action) {
      const payload = action.payload;

      console.log(payload)
      const options = []
      options.push(filter(PAYMENT_OPTIONS, p => p.networkCode === state.networkCode)[0])
      state.paymentOptions = options

      state.success = payload
      state.detail = payload.detail;
      state.errors = null
      if (state.networkCode === "1") {
        state.showComplete = true
        state.data = payload.data
      } else {
        state.showComplete = false
        state.MerchantRequestID = payload.MerchantRequestID;
        state.CheckoutRequestID = payload.CheckoutRequestID;
        state.CustomerMessage = payload.CustomerMessage

        state.showOTP = payload.OTPSent
        if (!payload.OTPSent) {
          state.showComplete = true
        }
      }

    },

    // POST PAYMENT PROCESSING SUCCESS
    processPaymentSuccess(state, action) {
      const payload = action.payload;
      state.success = payload
      state.showOTP = false
      state.paymentOptions = PAYMENT_OPTIONS
      state.showComplete = true
      state.ready = true
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, jamboPayCheckoutProcessor, getPaymentInfoSuccess, toggleOTP, paymentProcessingState, setComplete } = slice.actions;

// ----------------------------------------------------------------------


export function getPaymentInfo({ CheckoutId }) {
  return async () => {
    dispatch(slice.actions.setBasic({ CheckoutId }))
    dispatch(slice.actions.paymentProcessingState(false));
    try {
      // /apps/merchants-portal/api/v1/payments/link-payment/detail/
      const response = await axios.get('/payments/link-payment/detail/', {
        params: { checkout_id: CheckoutId }
      }).catch((error) => {
        dispatch(slice.actions.hasError(error));
      })
      if (response) {
        if (response.data.status) {
          dispatch(slice.actions.getPaymentInfoSuccess(response.data.data));
        } else {
          dispatch(slice.actions.hasError(response.data));
        }
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function initiatePayment({ amount, networkCode, phone, email, CheckoutId }) {
  dispatch(slice.actions.setNetworkCode(networkCode))

  return async () => {
    dispatch(slice.actions.paymentProcessingState(true));

    try {
      // https://api.sasapay.me/apps/merchants-portal/api/v1/payments/link-payment/v2/

      const response = await axios.post(networkCode === '1' ? '/payments/link-payment/' : '/payments/link-payment/v2/', {
        NetworkCode: networkCode,
        PhoneNumber: phone,
        Amount: parseInt(amount,10),
        Email: email,
        CheckoutId
      }).catch((error) => {
        dispatch(slice.actions.hasError(error));
      })

      if (response) {
        if (response.data.status) {
          dispatch(slice.actions.initiatePayment(response.data));
        } else {
          dispatch(slice.actions.hasError(response));
        }
      }

      dispatch(slice.actions.paymentProcessingState(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function processPayment({ networkCode, verificationCode, CheckoutId }) {
  dispatch(slice.actions.setNetworkCode(networkCode))
  return async () => {
    dispatch(slice.actions.paymentProcessingState(true));
    try {
      const response = await axios.post('/payments/link-payment/process/', {
        VerificationCode: verificationCode,
        CheckoutId
      }).catch((error) => {
        dispatch(slice.actions.hasError(error));
      })

      if (response) {
        if (response.data.status) {
          dispatch(slice.actions.processPaymentSuccess(response.data));
        } else {
          dispatch(slice.actions.hasError(response));
        }
      }
      dispatch(slice.actions.paymentProcessingState(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}